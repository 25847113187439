import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { RelayProvider } from '@opengsn/provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getAccount, getClient, getConnectorClient, switchChain } from '@wagmi/core';
import { clientToSigner, gsnRawProvider } from 'context/Web3Provider/helpers/providers';
import { providers } from 'ethers';
import {
  type Account,
  type Chain,
  type Client,
  type Transport,
  createPublicClient,
} from 'viem';
import {
  Connector,
  createConfig,
  http,
  useAccount,
  useClient,
  useConnect,
  useConnectorClient,
  UseConnectReturnType,
  useDisconnect,
  WagmiProvider
} from 'wagmi';
import { coinbaseWallet, walletConnect } from 'wagmi/connectors';

import { chainIdToNetworkMap, gsnConfigMap, networkConfigsMap, ORIGIN_NETWORK_NAME } from 'constants/config';

const queryClient = new QueryClient();

export const qTestnet = {
  id: networkConfigsMap.testnet.chainId,
  name: networkConfigsMap.testnet.name,
  nativeCurrency: { name: networkConfigsMap.testnet.networkName, symbol: 'QGOV', decimals: 18 },
  rpcUrls: {
    default: {
      http: [networkConfigsMap.testnet.rpcUrl],
    },
  },
  blockExplorers: {
    default: {
      name: 'Q explorer',
      url: networkConfigsMap.testnet.explorerUrl,
      apiUrl: networkConfigsMap.testnet.explorerApiUrl,
    },
  },
  testnet: true,
};

export const qDevnet = {
  id: networkConfigsMap.devnet.chainId,
  name: networkConfigsMap.devnet.name,
  nativeCurrency: { name: networkConfigsMap.devnet.networkName, symbol: 'QGOV', decimals: 18 },
  rpcUrls: {
    default: {
      http: [networkConfigsMap.devnet.rpcUrl],
    },
  },
  blockExplorers: {
    default: {
      name: 'Q explorer',
      url: networkConfigsMap.devnet.explorerUrl,
      apiUrl: networkConfigsMap.devnet.explorerApiUrl,
    },
  },
  testnet: true,
};

export const qMainnet = {
  id: networkConfigsMap.mainnet.chainId,
  name: networkConfigsMap.mainnet.name,
  nativeCurrency: { name: networkConfigsMap.mainnet.networkName, symbol: 'QGOV', decimals: 18 },
  rpcUrls: {
    default: {
      http: [networkConfigsMap.mainnet.rpcUrl],
    },
  },
  blockExplorers: {
    default: {
      name: 'Q explorer',
      url: networkConfigsMap.mainnet.explorerUrl,
      apiUrl: networkConfigsMap.mainnet.explorerApiUrl,
    },
  },
  sourceId: networkConfigsMap.mainnet.chainId,
};

export const config = createConfig({
  chains: [qTestnet, qDevnet, qMainnet],
  connectors: [
    walletConnect({
      projectId: '677ce68b8de1144dbff328c2460b4694',
      relayUrl: 'wss://relay.walletconnect.com',
      metadata: {
        name: 'Q DAO Factory',
        description: 'Q DAO Factory App',
        url: window.location.origin,
        // TODO: Change for infura or s3
        icons: ['https://q.org/favicon/favicon.ico']
      },
      isNewChainsStale: true,
    }),
    coinbaseWallet({
      appName: 'Q DAO Factory',
      version: '3',
      darkMode: true,
      // TODO: Change for infura or s3
      appLogoUrl: 'https://q.org/favicon/favicon.ico'
    })
  ],
  transports: {
    [qTestnet.id]: http(),
    [qDevnet.id]: http(),
    [qMainnet.id]: http(),
  },
});

const fallbackClient = createPublicClient({
  chain: qTestnet,
  transport: http(),
});

export type Web3ProviderContext<
  A extends Account | undefined = Account | undefined,
> = {
  connectManager: UseConnectReturnType;

  client: Client<Transport, Chain, A>;

  address: `0x${string}` | '';
  chain: Chain;
  isConnected: boolean;

  isRightNetwork: boolean;

  connect: (connector: Connector) => void;
  disconnect: () => void;

  gsnSigner?: providers.JsonRpcSigner;

  safeSwitchChain: (id: number) => Promise<void>;
};

const web3ProviderContext = createContext<Web3ProviderContext>({
  connectManager: {} as UseConnectReturnType,
  client: fallbackClient,

  address: '',
  chain: fallbackClient.chain,
  isConnected: false,

  isRightNetwork: false,

  connect: () => {},
  disconnect: () => {},

  gsnSigner: undefined,

  safeSwitchChain: async () => {},
});

export const useWeb3Context = () => {
  return useContext(web3ProviderContext);
};

export const Web3ContextProvider = (props: PropsWithChildren) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Web3ContextProviderContent>
          {props.children}
        </Web3ContextProviderContent>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

const Web3ContextProviderContent = (props: PropsWithChildren) => {
  const connectManager = useConnect();
  const [isInitializedGsnProvider, setIsInitializedGsnProvider] = useState(false);
  const account = useAccount();
  const { disconnect } = useDisconnect();

  const connect = useCallback(
    async (connector: Connector) => {
      connectManager.connect({ connector });
    },
    [connectManager],
  );

  const publicClient = useClient({ config });

  const { data: walletClient } = useConnectorClient({ config });

  const client = useMemo(() => {
    if (!account.chain) {
      return publicClient as Client<Transport, Chain>;
    }

    return walletClient as Client<Transport, Chain, Account>;
  }, [account.chain, publicClient, walletClient]);

  const isRightNetwork = useMemo((): boolean => {
    return Boolean(client?.chain?.id && chainIdToNetworkMap[client.chain.id]);
  }, [client?.chain?.id]);

  const gsnRelayProvider = useMemo(() => {
    if (!client?.chain?.id) return undefined;

    const gsnConfig = gsnConfigMap[chainIdToNetworkMap[Number(client.chain.id)]];

    if (!gsnConfig || !client) return undefined;

    if (!client.account) return undefined;

    const jsonRpcSigner = clientToSigner(client as Client<Transport, Chain, Account>);
    const web3ProviderBase = gsnRawProvider(jsonRpcSigner);
    const relayProvider = RelayProvider.newProvider({
      provider: web3ProviderBase as unknown as RelayProvider,
      config: {
        loggerConfiguration: { logLevel: 'error' },
        paymasterAddress: gsnConfig.paymaster,
        maxRelayNonceGap: 10000,
        preferredRelays: gsnConfig.relays,
      },
    });

    return relayProvider;
  }, [client]);

  const gsnSigner = useMemo((): providers.JsonRpcSigner | undefined => {
    if (!gsnRelayProvider || !isInitializedGsnProvider) return undefined;
    const gsnProvider = new providers.Web3Provider(
      gsnRelayProvider as unknown as providers.ExternalProvider,
    );

    return gsnProvider.getSigner();
  }, [gsnRelayProvider, isInitializedGsnProvider]);

  useEffect(() => {
    setIsInitializedGsnProvider(false);
    if (gsnRelayProvider && !gsnRelayProvider.connected) {
      ;(async () => {
        await gsnRelayProvider.init();
        setIsInitializedGsnProvider(true);
      })();
    }
  }, [gsnRelayProvider]);

  const safeSwitchChain = useCallback(async (id: number) => {
    await switchChain(config, { chainId: id });
  }, []);

  return (
    <web3ProviderContext.Provider
      value={{
        connectManager,

        client: client,

        address: account.address ?? '',
        chain: client?.chain,
        isConnected: account.isConnected,

        isRightNetwork,

        gsnSigner,

        connect,
        disconnect,

        safeSwitchChain,
      }}
    >
      {props.children}
    </web3ProviderContext.Provider>
  );
};

export const getCurrentClient = async (): Promise<Client<Transport, Chain, Account>> => {
  const account = getAccount(config);

  if (!account?.address) {
    return getClient(config) as Client<Transport, Chain, Account>;
  }

  const connectorClient = await getConnectorClient(config, {
    account: account.address,
    chainId: account.chainId ?? account.chain?.id,
  }) as Client<Transport, Chain, Account>;

  return connectorClient;

  // return await getWalletClient(config, {
  //   account: account.address,
  //   chainId: account.chainId ?? account.chain?.id
  // }) as unknown as Client<Transport, Chain, Account>;
};

export const getNetworkConfig = async () => {
  const currentClient = await getCurrentClient();

  const networkName = currentClient.chain?.id && chainIdToNetworkMap?.[currentClient.chain.id];

  return networkConfigsMap[networkName || ORIGIN_NETWORK_NAME];
};
