import { useWeb3Context } from 'context/Web3Provider';

import { chainIdToNetworkMap, networkConfigsMap, ORIGIN_NETWORK_NAME } from 'constants/config';

function useNetworkConfig () {
  const { chain } = useWeb3Context();
  const networkName = (chain.id && chainIdToNetworkMap?.[chain.id]) || ORIGIN_NETWORK_NAME;

  return networkConfigsMap[networkName || ORIGIN_NETWORK_NAME];
}

export default useNetworkConfig;
