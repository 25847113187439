import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Check, Modal } from '@q-dev/q-ui-kit';
import { useLocalStorage } from '@q-dev/react-hooks';
import styled from 'styled-components';

import ConnectButton from './ConnectButton';

import { RoutePaths } from 'constants/routes';

type Props = {
  onModalClose: () => void;
  modalOpen: boolean;
};

const StyledConnectWalletModal = styled(Modal)`
  z-index: 5;

  .connect-wallet-modal__terms {
    margin-top: 16px;
    align-items: center;
  }

  .connect-wallet-modal__terms,
  .connect-wallet-modal__terms-text {
    display: flex;
    gap: 8px;
  }

  .connect-wallet-modal__new-to-q {
    margin-top: 24px;
    text-align: center;
  }
  
  .connect-wallet-modal__new-to-q-link {
    display: inline-block;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function ConnectWalletModal ({ modalOpen, onModalClose }: Props) {
  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useLocalStorage('i-have-read-the-privacy-policy', false);

  return (
    <StyledConnectWalletModal
      open={modalOpen}
      title={t('CONNECT_WALLET')}
      onClose={onModalClose}
    >
      <>
        {isChecked && <ConnectButton />}

        <div className="connect-wallet-modal__terms">
          <Check
            value={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <div className="text-md">
            <span>{t('I_HAVE_READ')}</span>
            <div className="connect-wallet-modal__terms-text">
              <Link
                className="link"
                target="_blank"
                to={RoutePaths.privacy}
                rel="noreferrer"
              >
                {t('DATA_PRIVACY')}
              </Link>
              <span>{t('AND')}</span>
              <Link
                className="link"
                target="_blank"
                to={RoutePaths.imprint}
                rel="noreferrer"
              >
                {t('IMPRINT')}
              </Link>
            </div>
          </div>
        </div>

        <div className="connect-wallet-modal__new-to-q">
          <p className="text-md color-secondary">{t('NEW_TO_Q')}</p>
          <a
            className="connect-wallet-modal__new-to-q-link text-lg font-semibold"
            target="_blank"
            href="https://q.org"
            rel="noreferrer"
          >
            {t('LEARN_MORE_ABOUT_Q')}
          </a>
        </div>
      </>
    </StyledConnectWalletModal>
  );
}

export default ConnectWalletModal;
