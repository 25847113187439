import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useWeb3Context } from 'context/Web3Provider';
import { ErrorHandler, isMobile } from 'helpers';
import styled from 'styled-components';
import { Connector } from 'wagmi';

import Button from 'components/Button';

import { PROVIDER_NAMES } from 'constants/providers';

const StyledConnectButtons = styled.div`
  display: grid;
  gap: 8px;

  .connect-buttons__icon {
    width: 24px;
    height: auto;
  }
`;

const StyledConnectButtonsLoading = styled.div`
  text-align: center;
  height: 30px;
`;

function ConnectButton () {
  const { t } = useTranslation();
  const { connectManager, connect } = useWeb3Context();
  const [isLoading, setIsLoading] = useState(false);

  const buttons = [
    {
      name: 'WalletConnect',
      iconSrc: '/icons/walletconnect.svg',
      connector: connectManager.connectors.find(item => item.name === PROVIDER_NAMES.walletconnect),
    },
    {
      name: 'MetaMask',
      downloadLink: 'https://metamask.io/download',
      appConnectUrl: `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`,
      iconSrc: '/icons/metamask.svg',
      connector: connectManager.connectors.find(item => item.name === PROVIDER_NAMES.metamask),
    },
    {
      name: 'Coinbase',
      downloadLink: 'https://www.coinbase.com/wallet/downloads',
      appConnectUrl: `https://go.cb-w.com/dapp?cb_url=${encodeURIComponent(window.location.host + window.location.pathname)}`,
      iconSrc: '/icons/coinbase.png',
      connector: connectManager.connectors.find(item => item.name === PROVIDER_NAMES.coinbase),
    }
  ];

  const connectWallet = async (provider: Connector) => {
    setIsLoading(true);
    try {
      await connect(provider);
    } catch (error) {
      ErrorHandler.process(error, t('ERROR_WHILE_CONNECTING_TO_WALLET'));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  if (isLoading) {
    return <StyledConnectButtonsLoading>{t('LOADING')}</StyledConnectButtonsLoading>;
  }

  return (
    <StyledConnectButtons>
      {buttons.map((item, index) => (
        <Fragment key={index}>
          {item.connector
            ? (
              <Button
                alwaysEnabled
                style={{ width: '100%' }}
                onClick={() => { if (item.connector) { connectWallet(item.connector); } }}
              >
                <img
                  src={item.iconSrc}
                  alt={item.name}
                  className="connect-buttons__icon"
                />
                <span>{t('CONNECT_WITH_PROVIDER', { name: item.name })}</span>
              </Button>
            )
            : (
              <a
                href={isMobile() ? item.appConnectUrl : item.downloadLink}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  alwaysEnabled
                  block
                  style={{ width: '100%' }}
                >
                  <img
                    src={item.iconSrc}
                    alt={item.name}
                    className="connect-buttons__icon"
                  />
                  <span>
                    {isMobile()
                      ? t('GO_TO_PROVIDER', { name: item.name })
                      : t('INSTALL_PROVIDER', { name: item.name })
                    }
                  </span>
                </Button>
              </a>
            )}
        </Fragment>
      ))}
    </StyledConnectButtons>
  );
}

export default ConnectButton;
