import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useWeb3Context } from 'context/Web3Provider';

import logo from 'assets/img/logo.png';
import Button from 'components/Button';

import ConnectWallet from './components/ConnectWallet';
import Network from './components/Network';
import Settings from './components/Settings';
import WalletDropdown from './components/WalletDropdown';
import { StyledHeader } from './styles';

import { RoutePaths } from 'constants/routes';

function Header ({ onMenuClick }: { onMenuClick: () => void }) {
  const { t } = useTranslation();

  const { isConnected } = useWeb3Context();

  return (
    <StyledHeader>
      <div className="header__content">
        <div className="header__left">
          <div className="header__network">
            <Network />
          </div>
          <Button
            alwaysEnabled
            icon
            className="header__menu"
            look="secondary"
            onClick={onMenuClick}
          >
            <i className="mdi mdi-menu" style={{ fontSize: '20px' }} />
          </Button>
        </div>
        <Link to={RoutePaths.main} className="header__project">
          <img
            className="header__project-logo"
            alt="Q Logo"
            src={logo}
          />
          <span className="header__project-name">
            {t('DAO_FACTORY')}
          </span>
        </Link>
        <div className="header__actions">
          {isConnected
            ? <WalletDropdown />
            : <ConnectWallet />
          }
          <Settings />
        </div>
      </div>
    </StyledHeader>
  );
}

export default memo(Header);
