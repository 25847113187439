import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { StyleProvider } from '@q-dev/q-ui-kit';
import LanguageProvider from 'context/LanguageProvider';
import { Web3ContextProvider } from 'context/Web3Provider';

import Layout from 'components/Layout';
import NotificationManager from 'components/NotificationManager';
import Web3Initializer from 'components/Web3Initializer';
import Routes from 'navigation/Routes';

import '@mdi/font/css/materialdesignicons.min.css';

const root = createRoot(document.getElementById('root') as Element);

root.render(
  <StyleProvider>
    <LanguageProvider>
      <Web3ContextProvider>
        <Web3Initializer>
          <BrowserRouter>
            <Layout>
              <Routes />
            </Layout>
          </BrowserRouter>
        </Web3Initializer>
      </Web3ContextProvider>
      <NotificationManager />
    </LanguageProvider>
  </StyleProvider>,
);
